<template>
  <keep-alive>
    <div style="max-width: 100%;min-height: 100%;background: #F9FAFD;padding: 0 24rem 24rem 0;box-sizing: border-box">
      <transform>
        <div class="fixed-full" v-loading="loading" v-if="loading"></div>
      </transform>
      <div class="header">
        <info-left :all-info="info" :oss="oss" :value="info.base"></info-left>
        <info-right :oss="oss" :value="info.family"></info-right>
      </div>

      <div class="Counseling">
        <Counseling :oss="oss" @link="onLink"></Counseling>
      </div>

      <div class="ClassScheduleAndLink">
        <ClassSchedule :value="info"></ClassSchedule>
        <ToLink :oss="oss" :value="info" @onToLink="onToLink" @refresh="onRefresh"></ToLink>
      </div>

      <!-- <div class="echarts" style="height: 500rem !important;">
        <StudyHabits :oss="oss" :value="info.moralEducation"></StudyHabits>
        <ChangeInPerformance :value="statisticsConfig"></ChangeInPerformance>
      </div> -->

      <div class="echarts" style="height: 520rem !important;">
        <MoralHabit :oss="oss" :value="statisticsConfig"></MoralHabit>
        <OneToOne :value="statisticsConfig" :value1="coach_educational"></OneToOne>
      </div>

      <div class="HistoricalAchievements">
        <HistoricalAchievements :all-info="info"></HistoricalAchievements>
      </div>

      <div class="footer" v-if="$route.path.includes('detail')">
        <Mbti :value="testData"></Mbti>
        <Mht :value="testData"></Mht>
        <Scl :value="testData"></Scl>
      </div>

      <el-button style="margin-top: 24rem" type="primary" @click="$router.back()">返回</el-button>
    </div>
  </keep-alive>
</template>

<script>
import InfoLeft from "@/views/edu/edu-info/student-manager/components/Info/InfoLeft.vue";
import InfoRight from "@/views/edu/edu-info/student-manager/components/Info/InfoRight.vue";
import Counseling from "@/views/edu/edu-info/student-manager/components/Counseling/Counseling.vue";
import ClassSchedule from "@/views/edu/edu-info/student-manager/components/ClassSchedule/ClassSchedule.vue";
import ToLink from "@/views/edu/edu-info/student-manager/components/ToLink/ToLink.vue";
import StudyHabits from "@/views/edu/edu-info/student-manager/components/Echarts/StudyHabits.vue";
import OneToOne from "@/views/edu/edu-info/student-manager/components/Echarts/OneToOne.vue";
import MoralHabit from "@/views/edu/edu-info/student-manager/components/Echarts/MoralHabit.vue";
import ChangeInPerformance from "@/views/edu/edu-info/student-manager/components/Echarts/ChangeInPerformance.vue";
import HistoricalAchievements from "@/views/edu/edu-info/student-manager/components/HistoricalAchievements/HistoricalAchievements.vue";
import Scl from "@/views/edu/edu-info/student-manager/components/Scl/Scl.vue";
import Mht from "@/views/edu/edu-info/student-manager/components/Mht/Mht.vue";
import Mbti from "@/views/edu/edu-info/student-manager/components/Mbti/Mbti.vue";


export default {
  _config:{route:[{path:'detail',meta:{title:'详情'}},{path:'view',meta:{title:'查看'}}]},
	data() {
		return {
      loading: false,
			oss: process.env.VUE_APP_URL_OSS + "student-management/",
			info: {
				base: {},
				family: {},
			},
			statisticsConfig: {},
			coach_educational: {},
			testData: {},
		};
	},
	components: {
		Scl,
		Mht,
		Mbti,
		HistoricalAchievements,
		ChangeInPerformance,
		MoralHabit,
		OneToOne,
		StudyHabits,
		ToLink,
		ClassSchedule,
		Counseling,
		InfoRight,
		InfoLeft,
	},
	methods: {
		getInfo() {
      this.loading = true
			this.$_axios2.get("api/student/details/base-info?student_id=" + this.$route.query.id).then((res) => {
				this.info = res.data.data;
			}).finally(() => this.loading = false)
		},
		onRefresh() {
			this.getInfo();
		},
		onToLink() {
      this.$router.push("/moral/teacher-work/heart-to-heart-talk/edit?id=" + this.info.talk_id + "&show=" + this.info.talk_id);
		},
    onLink(url) {
      this.$router.push(url + '?student_name=' + this.info.base.student_name);
    }
	},
	created() {
		this.getInfo();
		this.$_axios2.get("api/student/details/statistics-config?student_id=" + this.$route.query.id).then((res) => {
			this.statisticsConfig = res.data.data.educational_routine;
			this.coach_educational = res.data.data.coach_educational;
		});
		this.$_axios2.get("api/student/details/test?student_id=" + this.$route.query.id).then((res) => {
			this.testData = res.data.data;
		});
	},
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
}

.Counseling {
	margin-top: 14rem;
	margin-bottom: 24rem;
}

.ClassScheduleAndLink {
	display: flex;
	justify-content: space-between;
}

.echarts {
	margin-top: 24rem;
	height: 462rem;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
}

.HistoricalAchievements {
	margin: 24rem 0;
}

.footer {
	display: flex;
	justify-content: space-between;
}

</style>

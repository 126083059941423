<script>

import * as echarts from "echarts";
import TResult from "@/components/Result.vue";
import EchartsIcon from "@/views/edu/edu-info/student-manager/components/Echarts/EchartsIcon.vue";

export default {
	name: "OneToOne",
	components: {TResult, EchartsIcon},
	props: {
		value: {
			type: Object,
			default: () => {
				return {};
			},
		},
		value1: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		value: function (newVal) {
			newVal.subject.forEach((item) => {
				if (item.show === 1) {
					this.params.subject_id = item.subject_id;
				}
			});
			if (!newVal.subject.length) {
				this.params.subject_id = 0;
			}
			this.init();
		},
	},
	data() {
		return {
			selected: 1,
			params: {
				student_id: this.$route.query.id,
				subject_id: "",
				type: "paper_analysis",
				range: 0,
			},
			chartData: [],
		};
	},
	methods: {
		onSelect(index) {
			this.params.range = index;
			this.init();
		},
		init() {
			this.$_axios2.get("api/student/details/subject-tutor", {params: this.params}).then((res) => {
				this.chartData = res.data.data;
				if (!this.chartData.length) return;
				const color = ["#FF4D4D", "#B1C3FF", "#8F5FFF", "#2FD931", "#FD9E64"];
				this.chartData.forEach((item, index) => {
					item.itemStyle = {
						color: color[index],
					};
				});
				this.$nextTick(() => {
					let chartDom = document.getElementById("main1");
					let myChart = echarts.init(chartDom);
					let option = {
						tooltip: {
							trigger: "item",
							formatter: function (params) {
								return `<div style="display: flex;align-items: center">
										<div style="width: 6rem;height: 6rem;background: ${params.color};border-radius: 50%;margin-right: 10rem;margin-top: -6rem"></div>
										<div style="font-size: 16rem;">${params.name}</div>
							</div>` + `<span style='font-size: 16rem;color: #3D3D3D;margin-left: 14rem'>${params.percent.toFixed(0)}%</span>`;
							},
						},
						legend: {
							bottom: "5%",
							left: "left",
							orient: "vertical",
							itemWidth: 18,
							itemHeight: 18,
              textStyle: {
                fontSize: 14, // 设置字体大小
                color: "#333" // 可选，设置字体颜色
              },
							formatter: function (name) {
								let data = option.series[0].data;
								let total = 0;
								let tarValue;
								for (let i = 0; i < data.length; i++) {
									total += Number(data[i].value);
									if (data[i].name === name) {
										tarValue = Number(data[i].value);
									}
								}
                let p = total===0?0: Math.round(((Number(tarValue) / Number(total)) * 100));
								return `${name}  ${p}%`;
							},
						},
						series: [
							{
								type: "pie",
								radius: ["30%", "75%"], center: ["60%", "55%"],
                itemStyle: res.data.data.filter(item => item.value !== "0.00").length > 1 ? {
                  borderRadius: 8,
                  borderColor: "#FFF",
                  borderWidth: 4,
                } : {
                  borderRadius: 0, // 重置样式
                  borderColor: "transparent",
                  borderWidth: 0,
                },
								label: {
									show: false,
								},
								labelLine: {},
								data: this.chartData,
							},
						],
					};
					myChart.setOption(option);
          window.addEventListener('resize', function () {
            myChart.resize();
          });
				});
			});
		},
		onSelectTwo(val) {
			if (typeof val === "number") this.params.subject_id = val;
			else this.params.type = val;
			this.init();
		},
	},
};
</script>

<template>
	<div>
		<EchartsIcon text="学科一对一辅导学情统计"></EchartsIcon>
		<div style="width: 600rem;height: 460rem;background: #FFFFFF;padding: 24rem;box-sizing: border-box;display: flex;flex-direction: column">
			<div style="display: flex;align-items: center;justify-content: space-between">
				<div style="width: 142rem;height: 40rem;background: #F9FAFD;border-radius: 0 4rem 4rem 0;display: flex">
					<div :class="!params.range ?'selected':''" class="text-1" @click="onSelect(0)">本学期</div>
					<div :class="params.range ?'selected':''" class="text-1" @click="onSelect(1)">全部</div>
				</div>
				<div>
					<el-select v-model="params.subject_id" style="width: 160rem;margin-right: 24rem" @change="onSelectTwo">
						<el-option :value="0" label="全部"></el-option>
						<el-option v-for="i in value.subject" :label="i.name" :value="i.subject_id"></el-option>
					</el-select>
					<el-select v-model="params.type" style="width: 160rem;margin-right: 24rem" @change="onSelectTwo">
						<el-option v-for="(i,k) of value1" :label="i" :value="k"></el-option>
					</el-select>
				</div>
			</div>
			<div v-if="chartData.length" id="main1" style="flex: 1;width: 100%;height: 100%"></div>
			<t-result v-else style="width: 100%;padding: 50rem 0;" text="暂无数据" type="empty"></t-result>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.text-1 {
	width: 72rem;
	height: 40rem;
	border-radius: 4rem;
	font-weight: normal;
	font-size: 14rem;
	line-height: 40rem;
	text-align: center;
	color: #888888;
}

.selected {
	background: #E1EAFF;
	color: #1C4EFD;
}
</style>

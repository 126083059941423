<script>
import TimeLine from "@/components/common/TimeLine.vue";
import Dialog from "@/components/common/Dialog.vue";
import TResult from "@/components/Result.vue";

export default {
	name: "ToLink",
	components: {TResult, Dialog, TimeLine},
	props: {
		oss: {
			type: String,
			default: "",
		},
		value: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	data() {
		return {
			items: ["综合素质评价", "考勤记录", "交心谈话记录", "历史分班记录", "艺体转入/转出记录", "修改政治面貌", "疑问解答", "专项讲解"],
			politicsStatusPopShow: false,
			politicsStatusLoading: false,
			historyPop: false,
			asDialogLoading: false,
			historyData: [],
			politicsStatusForm: {},
			politicsStatusSelectConfig: [],
			asDialogList: [],
		};
	},
	created() {
		this.$_axios2("/api/common/screen/political_type").then(res => {
			let {data} = res.data;
			this.politicsStatusSelectConfig = data;
		});
		this.$_axios("/students/class", {params: {id: this.$route.query.id}}).then(res => {
			let {data} = res.data;
			if (data.length) {
				let arr = [],
					arr_item = [];
				data.forEach((item, index) => {
					item.time1 = item.date.slice(0, 10);
					item.time2 = item.date.slice(11);

					if (Number(item.type) === 1 && index !== 0) {
						let a = JSON.parse(JSON.stringify(arr_item));
						arr.push(a);
						arr_item = [];
					}
					arr_item.push(item);
					if (index === data.length - 1) {
						arr.push(arr_item);
					}
				});
				this.historyData = arr;
			}
		});
		this.$_axios2("api/student/details/art-record", {params: {student_id: this.$route.query.id}}).then(res => {
			this.asDialogList = res.data.data.map(i => {
				const arr = [];
				if (i.out_reason) {
					arr.push(`转${i.type === 1 ? "入" : "出"}原因：${i.out_reason}`);
				}
				if (i.reject_reason) {
					arr.push(`${i.status === 1 ? "通过" : "驳回"}原因：${i.reject_reason}`);
				}
				return {id: i.id, content: i.describe, date: i.created_at, type: i.status === 1 ? "primary" : "danger", arr};
			});
		});
	},
	methods: {
		onSubmit() {
			let params = this.politicsStatusForm;
			params.user_id = this.$route.query.id;
			if (!params.political_type) {
				this.$message.warning("请选择一个政治面貌");
				return;
			}
			this.politicsStatusLoading = true;
			this.$_axios2.post("/api/student/political-outlook/add", params).then(res => {
				if (res.data.status === 0) {
					this.$message({
						message: "修改成功",
						type: "success",
						onClose: () => {
							this.politicsStatusPopShow = false;
							this.politicsStatusForm.political_type = "";
							this.politicsStatusForm.change_reason = "";
							this.$emit("refresh");
						},
					});
				}
			}).finally(() => {
				this.politicsStatusLoading = false;
			});
		},
		onToLink(item) {
      if (item === "综合素质评价") {
        this.$router.push("/edu/edu-info/student-manager/comprehensive-quality?id=" + this.$route.query.id);
      }
      const arr = ['留级记录','休学记录','集团间转学记录','退学记录']
      if (arr.includes(item)) {
        let idx = arr.findIndex(i => i === item)
        if (idx >= 0) {
          idx = idx + 1
          this.$router.push(`/moral/student-moral-management/status-management/list?type=${idx}&student_name=${this.value.base.student_name}`);
        }
      }
			if (item === "疑问解答") {
				this.$router.push("/edu/process-record/answer/list?name=" + this.value.base.student_name + '&activeName=0');
			}
			if (item === "专项讲解") {
				this.$router.push("/edu/process-record/explain/list?name=" + this.value.base.student_name);
			}
			if (item === "考勤记录") {
				this.$router.push("/edu/work/student/list?name=" + this.value.base.student_name);
			}
			if (item === "修改政治面貌") {
				this.politicsStatusPopShow = true;
			}
			if (item === "历史分班记录") {
				this.historyPop = true;
			}
			if (item === "交心谈话记录") {
				this.$emit("onToLink");
			}
			if (item === "艺体转入/转出记录") {
				if (this.asDialogList.length) this.asDialogLoading = true;
				else this.$message.error("暂无数据");
			}
		},
	},
};
</script>

<template>
	<div>
    <div :style="{backgroundImage: `url(${oss}link.png)`, backgroundSize: '100% 100%'}" style="width: 352rem;height: 100%;display: flex;flex-direction: column">
			<div style="height: 53rem;width: 100%"></div>
			<div style="flex: 1;width: 100%;overflow-y: auto;padding: 0 23rem 24rem">
				<a v-for="(i, k) of value.entrance" href="javascript:" style="height: 62rem;line-height: 62rem;padding-left: 7rem;padding-right: 21rem;box-sizing: border-box;border-bottom: 1rem solid #ccc;display: flex;justify-content: space-between;align-items: center" @click="onToLink(k)">
					<img :src="oss+i" alt="" style="width: 30rem;height: 30rem;margin-right: 12rem">
					<span style="font-weight: 600;font-size: 16rem;color: #3C3C3C;flex: 1">{{ k }}</span>
					<i class="el-icon-arrow-right"></i>
				</a>
			</div>
		</div>
		<el-dialog :visible.sync="politicsStatusPopShow" title="修改政治面貌" width="800px">
			<el-form ref="form" :model="politicsStatusForm" label-width="130px">
				<el-form-item label="选择最新政治面貌">
					<el-select v-model="politicsStatusForm.political_type" :popper-append-to-body="false" placeholder="请选择一个政治面貌" size="small">
						<el-option v-for="item in politicsStatusSelectConfig" :label="item.political_outlook" :value="item.political_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="说明变更原因">
					<el-input v-model="politicsStatusForm.change_reason" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入变更原因" size="small" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
        <el-button :loading="politicsStatusLoading" type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="politicsStatusPopShow = false">取消</el-button>
      </span>
		</el-dialog>
		<el-dialog :visible.sync="historyPop" custom-class="history-dialog" title="查看历史分班记录">
			<div v-for="item in historyData" v-if="historyData.length > 0" class="history-box flex flex-between">
				<div class="flex flex-between" style="width: 120px">
					<div class="step-box" style="padding-top: 16px">
						<div class="cir"></div>
						<span class="cir-line"></span>
					</div>
					<div>
						<p class="c3 f-bold">{{ item[0].time1 }}</p>
						<p class="c6">{{ item[0].time2 }}</p>
					</div>
				</div>
				<div :class="item.type === 0 ? 'padding-top' : ''" style="width: calc(100% - 150px); padding: 50px 0 30px">
					<div v-for="c_item in item">
						<div v-if="Number(c_item.type) === 1" class="flex">
							<div class="div1 f-bold" style="background-color: #e8edff">
								<div>科目</div>
								<div>层级</div>
								<div>班级</div>
							</div>
							<div v-for="cc_item in c_item.info" class="div1">
								<div>{{ cc_item.subject_name }}</div>
								<div>{{ cc_item.grade }}</div>
								<div>{{ cc_item.class_name }}</div>
							</div>
						</div>
						<div v-if="Number(c_item.type) === 0" class="flex flex-between c3" style="background-color: #f7f7f7; line-height: 40px; padding: 0 20px; margin-top: 30px">
							<p>
								{{ c_item.info[0].subject_name }}
								{{ c_item.info[0].grade ? "【" : "" }}
								{{ c_item.info[0].grade }}
								{{ c_item.info[0].grade ? "层】" : "" }}
								{{ c_item.info[0].class_name }}
								调整为
								{{ c_item.info[1].subject_name }}
								{{ c_item.info[1].grade ? "【" : "" }}
								{{ c_item.info[1].grade }}
								{{ c_item.info[1].grade ? "层】" : "" }}
								{{ c_item.info[1].class_name }}
							</p>
							<p>{{ c_item.date }}</p>
						</div>
					</div>
				</div>
			</div>
			<t-result v-if="!historyData.length" :type="'empty'"></t-result>
		</el-dialog>
		<Dialog :showFooter="false" :visible.sync="asDialogLoading" title="艺体转入/转出记录">
			<TimeLine :list="asDialogList" line="solid" reverse></TimeLine>
		</Dialog>
	</div>
</template>

<style lang="scss" scoped>
::v-deep .history-dialog {
	width: fit-content !important;
	min-width: 900px !important;
	max-width: 1600px !important;
	max-height: 71vh !important;
	overflow-y: auto;
}

.history-box {
	.step-box {
		position: relative;
	}

	.cir {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 22px;
		height: 22px;
		background-color: #1d2088;
		border-radius: 50%;
		z-index: 1;
	}

	.cir-line {
		position: absolute;
		top: 22px;
		left: 50%;
		transform: translateX(-50%);
		width: 1px;
		height: 100%;
		background-color: #ccc;
	}

	.div1 > div {
		word-break: keep-all;
		white-space: nowrap;
		padding-right: 40px;
		height: 66px;
		line-height: 66px;
		padding-left: 20px;
		color: #333;
	}

	&:last-child {
		.cir-line {
			display: none;
		}
	}
}

.c3,
.c6 {
	line-height: 28px;
}

</style>
